import React from "react"

const TopHeaderPromo = ({ text, backgroundColor, textColor }) => (
  <section style={{ backgroundColor: `${backgroundColor}` }}>
    <div className="container container--lg margin-top--xxxs margin-bottom--xxxs">
      <div className="text--center">
        <p style={{ color: `${textColor}` }}>
          {text}
          <a
            data-goal="Onboarding Viewed"
            href="https://us-onboarding.jointacademy.com/"
            style={{
              color: "#06E55C!important",
              textDecoration: "underline",
            }}
          >
            Click Here
          </a>
        </p>
      </div>
    </div>
  </section>
)

export default TopHeaderPromo
