import React from 'react';
import { string, node } from 'prop-types';
import Button from '../Button';

const Header = ({
  siteTitle,
  headerImage,
  renderCallCTA,
  headerStyle,
  headerWitdh,
  ButtonHeader,
  dataTheme,
}) => (
  <header
    className={`header ${headerWitdh}`}
    id="header--default"
    data-theme={dataTheme}
  >
    <div>
      <div
        className={`flex flex--start ${headerStyle}`}
        style={{ alignItems: 'center' }}
      >
        <div className="flex flex--center">
          <img
            className="logo--md flex"
            src="https://assets.jointacademy.com/logos/jointacademy_logo_pos.svg"
            alt={siteTitle}
          />

          {headerImage}

        </div>
        {renderCallCTA && (
          <div className="flex text--right text--xs">
            <p>Questions? &nbsp;</p>
            <div className="flex flex--end">
              <p>Call</p>
              <a
                data-goal="Phone Clicked"
                className="margin-left--xxs"
                href="tel:+19295476370"
              >
                {' '}
                +1 929-547-6370
              </a>
            </div>
          </div>
        )}
        {ButtonHeader && (
          <div className="flex text--right text--xs">
            <Button
              href="#jobs"
              text="Show jobs"
              classname="btn btn--primary btn--xs"
            />
          </div>
        )}
      </div>
    </div>
  </header>
);

Header.defaultProps = {
  siteTitle: '',
  headerImage: undefined,
};

Header.propTypes = {
  siteTitle: string,
  headerImage: node,
};

export default Header;
