import React from "react"
import BlueshieldcaTop from "../../../assets/blueshieldca.svg"

const TopHeaderLogo = ({ text, blueshieldcaTop }) => (
  <section data-theme="white">
    <div className="container container--lg margin-top--md margin-bottom--md">
      <div className="flex flex--center text--center">
        <p className="text--md">{text}</p>
        <div className="logo--xs" style={{ width: "170px" }}>
          {blueshieldcaTop && (
            <img
              className="logo--md margin-left--xs flex"
              alt="humana"
              src={BlueshieldcaTop}
            />
          )}
        </div>
      </div>
    </div>
  </section>
)

export default TopHeaderLogo
