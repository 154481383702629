import React from 'react';

const allContent = {
  us: {
    links: [
      {
        text: 'Privacy policy',
        href: 'https://www.jointacademy.com/us/en/policy/',
      },
      {
        text: 'Terms of use',
        href: 'https://www.jointacademy.com/us/en/terms/',
      },
      {
        text: 'HIPAA',
        href: 'https://www.jointacademy.com/us/en/hipaa/',
      },
    ],
  },
  se: {
    links: [
      {
        text: 'Personuppgiftspolicy',
        href: 'https://www.jointacademy.com/se/sv/policy/',
      },
      {
        text: 'Allmänna Villkor',
        href: 'https://www.jointacademy.com/se/sv/villkor/',
      },
      {
        text: 'CE-märkning',
        href: 'https://www.jointacademy.com/se/sv/ce/',
      },
    ],
  },
  uk: {
    links: [
      {
        text: 'Privacy policy',
        href: 'https://www.jointacademy.com/gb/en/policy/',
      },
      {
        text: 'Terms of use',
        href: 'https://www.jointacademy.com/gb/en/terms/',
      },
    ],
  },
  fr: {
    links: [
      {
        text: 'CGU du Site web',
        href: 'https://www.jointacademy.com/fr/fr/conditions-generales-dutilisation-du-site-web/',
      },
      {
        text: 'Mention Légales',
        href: 'https://www.jointacademy.com/fr/fr/mention-legales/',
      },
      {
        text: 'Politique de confidentialité Site Internet',
        href: 'https://www.jointacademy.com/fr/fr/politique-de-confidentialite-site-internet/',
      },
      {
        text: 'CGU Patients',
        href: 'https://www.jointacademy.com/fr/fr/conditions-generales-dutilisation-utilisateurs-patients/',
      },
      {
        text: 'Politique de confidentialité Utilisateurs Patients',
        href: 'https://www.jointacademy.com/fr/fr/politique-de-confidentialite-utilisateurs-patients/',
      },
      {
        text: 'Accord sur la protection des données de l’Utilisiateurs Patient',
        href: 'https://www.jointacademy.com/fr/fr/accord-sur-la-protection-des-donnees-de-lutilisiateurs-patient/',
      },
      {
        text: 'CGU Professionnels',
        href: 'https://www.jointacademy.com/fr/fr/conditions-generales-dutilisation-utilisateurs-professionnels/',
      },
      {
        text: 'Politique de confidentialité Utilisateurs Professionnels',
        href: 'https://www.jointacademy.com/fr/fr/politique-de-confidentialite-utilisateurs-professionnels/',
      },
    ],
    text: 'Joint Academy FR, SAS au capital [1,000 EUROS], 60 rue François 1er, 75008, Paris c/o Legalplace, RCS Paris 814 428 785. Joint Academy FR, est une filiale de la société suédoise Arthro Therapeutics AB (556941-9210), propriétaire de la application et plateforme Joint Academy. Données de santé sécurisées, soumises au secret médical.\n'
      + '<br> <br>'
      + 'Joint Academy, dispositif médical est un produit de santé réglementé qui porte au titre de cette réglementation, le marquage CE.',
  },
};

const Footer = ({ lang }) => {
  const language = typeof lang === 'undefined' ? 'se' : lang;

  const content = allContent[language];

  return (
    <footer id="footer" className="text--xs skinny-footer" data-theme="white">
      <div className="container container--lg margin-top--md margin-bottom--md">
        <div className="skinny-footer__content">
          <div className="skinny-footer__logo-wrapper">
            <a href="https://jointacademy.com/" target="_blank" rel="noreferrer">
              <img
                src="https://assets.jointacademy.com/logos/jointacademy_logo_pos.svg"
                alt="logoFot"
              />
            </a>
          </div>
          <div className="skinny-footer__links-wrapper">
            {content.links.map((link) => (
              <a
                href={link.href}
                target="_blank"
                rel="noreferrer"
                key={link.text}
              >
                {link.text}
              </a>
            ))}
          </div>
          {content.text && (
            <div className="skinny-footer__text-wrapper">
              <p dangerouslySetInnerHTML={{ __html: content.text }} />
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};
export default Footer;
