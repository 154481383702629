import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroCTA from '@components/sections/heroCTA/heroCTA';
import Layout from '@components/layout';
import Segment from '@components/segment';
import Seo from '@components/seo';
import TestimonialGridST from '@components/staticSections/TestimonialGridST/TestimonialGridST';

const IndexPage = () => (
  <Layout
    lang="us"
    headerWitdh="header"
    topHeader
    text="Cubierto por tu seguro médico sin costo alguno"
    showFooter
  >
    <Helmet>
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
        f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NCWLPMM');`}
      </script>
    </Helmet>

    <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
    <Seo title="Alignment Healthcare" language="es-US" />
    <HeroCTA
      dataTheme="ice-blue"
      imagedata4
      dataSection="hero_CTA"
      alt="Alignment Healthcare"
      title="Tratamiento para el dolor de tus articulaciones y espalda baja dese la comodidad de tu hogar"
      titleClassName="bold"
      text="A continuacion, porfavor pon donde esta indicado tu nombre, numero telefonico, tu correo electronico. Una persona de nuestra compania que habla Espanol se pondra en contacto con usted"
      textClassName=""
      bottomText={(
        <>
          Tienes preguntas ? Llama al
          {' '}
          <a href="tel:929-547-6370">929-547-6370</a>
        </>
      )}
      formSubtextClassname="margin-top--sm"
      showHeroBrazeForm
      heroBrazeFormSettings={{
        brazeSource: 'alignmentESlp',
        showPhoneInput: true,
      }}
      lang="es"
    />

    <TestimonialGridST lang="us_es" />
  </Layout>
);

export default IndexPage;
