/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet-async"
import { useStaticQuery, graphql } from "gatsby"

function Seo({
  description,
  lang,
  meta,
  title,
  language,
  ogImage: ogImageProp,
}) {
  const { site, ogImageDefault } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
        ogImageDefault: file(relativePath: { eq: "og-image.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, height: 630, width: 1200)
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const ogImage =
    ogImageProp ||
    ogImageDefault.childImageSharp.gatsbyImageData.images.fallback.src

  return (
    <Helmet
      htmlAttributes={{ lang: `${language}` }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `theme-color`,
          content: `#1274e3`,
        },

        {
          property: `og:image`,
          content: ogImage,
        },

        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: `noindex, nofollow`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    >
      {/* <script src="https://cdn.optimizely.com/js/20574050108.js"></script> */}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  ogImage: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
