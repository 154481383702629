/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './elements/header';
import HeaderFloat from './elements/headerFloat';
import Footer from './elements/footer/footer';
import TopHeader from './sections/TopHeader/TopHeader';
import TopHeaderPromo from './sections/topHeaderPromo/topHeaderPromo';
import TopHeaderLogo from './sections/topHeaderLogo/topHeaderLogo';

const Layout = ({
  children,
  topHeader,
  topHeaderLogo,
  topHeaderPromo,
  HeaderFloatShow,
  text,
  renderCallCTA,
  headerContainerStyle,
  headerStyle,
  headerWitdh,
  showFooter,
  lang,
  textColor,
  backgroundColor,
  ButtonHeader,
  dataTheme,
  blueshieldcaTop,
  headerImage,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      {topHeader && (
        <TopHeader
          text={text}
          textColor={textColor}
          backgroundColor={backgroundColor}
        />
      )}
      {topHeaderPromo && (
        <TopHeaderPromo
          text={text}
          textColor={textColor}
          backgroundColor={backgroundColor}
        />
      )}
      {topHeaderLogo && (
        <TopHeaderLogo
          text={text}
          logo={textColor}
          blueshieldcaTop={blueshieldcaTop}
        />
      )}
      {HeaderFloatShow ? (
        <HeaderFloat
          headerWitdh={headerWitdh}
          headerContainerStyle={headerContainerStyle}
          headerStyle={headerStyle}
          renderCallCTA={renderCallCTA}
          siteTitle={data.site.siteMetadata?.title || ''}
          dataTheme={dataTheme}
        />
      ) : (
        <Header
          headerWitdh={headerWitdh}
          headerStyle={headerStyle}
          renderCallCTA={renderCallCTA}
          siteTitle={data.site.siteMetadata?.title || ''}
          ButtonHeader={ButtonHeader}
          dataTheme={dataTheme}
          headerImage={headerImage}
        />
      )}
      <main className="content">{children}</main>
      {showFooter && <Footer lang={lang} />}
    </>
  );
};

export default Layout;
