import React from "react"

const TopHeader = ({ text }) => (
  <section data-theme="electric-blue">
    <div className="container container--lg margin-top--xxxs margin-bottom--xxxs">
      <div className="text--center">
        <p>{text}</p>
      </div>
    </div>
  </section>
)

export default TopHeader
