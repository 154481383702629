import React from "react"
import contentData from "../../../components/staticSections/TestimonialGridST/data.json"
import Video from "../../Video"

const TestimonialGridST = ({ lang }) => {
  const language = typeof lang === "undefined" ? "se_sv" : lang
  const content = contentData[language]
  return (
    <section data-theme="white" className="TestimonialGridST">
      <div className="container container--lg margin-top--lg margin-bottom--lg">
        {content &&
          content.map(main => (
            <div key={main} className="grid grid--gap-md text--center">
              {main.videos &&
                main.videos.map(video => (
                  <div key={video.videoID} className="col col--12 col--md-6">
                    <div className="text--center margin-bottom--xs">
                      <h2 className="text--md">{video.title}</h2>
                    </div>
                    <div className="case-video">
                      <div className="case-video-cover flex flex--center">
                        <div className="play flex flex--column flex--center">
                          <span
                            id="play-button-case"
                            className="btn btn--primary flex flex--center aniamtion--ping margin-bottom--sm margin-top--sm"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 27.999 32.001"
                            >
                              <path
                                fill="#fff"
                                d="M12.525-14.581l-22-13.006A2.983,2.983,0,0,0-14-25.006V1A3,3,0,0,0-9.475,3.581l22-13A3,3,0,0,0,12.525-14.581Z"
                                transform="translate(14 27.998)"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <Video
                        videoSrcURL={`https://player.vimeo.com/video/${video.videoID}?color=fff&title=0&byline=0&portrait=0`}
                        videoTitle={video.title}
                      />
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </div>
    </section>
  )
}
export default TestimonialGridST
