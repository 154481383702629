import React from "react"
import PropTypes from "prop-types"

// import "./headerFloat.scss"
// import styled from "styled-components"
// import { respondTo } from "../atoms/_respondTo"

// export const Logo = styled.div`
//   margin-top: 22px !important;

//   div {
//     img {
//       justify-content: center;
//       margin: 0 auto;
//       ${respondTo.md`
//         margin-top: 42px !important;
//         justify-content: center;

//     `}
//       ${respondTo.xs`
//        margin: 0 auto;
//       text-align: center;
//       justify-content: start;
//     `}
//     }
//   }
//   ${respondTo.xs`
//     justify-content: center ;
//   `}
// `

function HeaderFloat({ siteTitle, headerContainerStyle, headerStyle, headerWitdh }) {
  // const HeaderFloat = styled.header`
  //   position: absolute;
  //   z-index: 99;
  //   background-color: unset !important;
  //   justify-content: center;
  //   margin: 0 auto;
  //   text-align: center;
  //   ${respondTo.xs`
  //      margin: 0 auto;
  //     text-align: left;
  //     justify-content: start;
  //   `}
  // `

  return (
    <header className={`HeaderFloatContainer ${headerContainerStyle} ${headerWitdh}`} id="">
      <div>
        <div className={`logoContainer ${headerStyle}`}>
          <img
            className="logo--md margin-top--xs  "
            src="https://assets.jointacademy.com/logos/jointacademy_logo_pos.svg"
            alt={siteTitle}
          />
        </div>
      </div>
    </header>
  )
}

HeaderFloat.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderFloat.defaultProps = {
  siteTitle: ``,
}

export default HeaderFloat
